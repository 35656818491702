<template>
	<div id="page" :style="bg ? `background-image: url('${bg}');` : ''">
		<div class="content">
			<vue-baberrage
			:barrageList = "barrageList"
			loop = "true"
			maxWordCount = "100"
			>
		  </vue-baberrage>
		</div>
		<template v-if="menuItems">
		<toggle-menu
			position="RB"
			:menuItems="menuItems"
            @clickMenu="clickMenu"
			menuBg="#2A3E53"
			itemBg="#2A3E53"
        />
		</template>
	</div>
</template>

<script>
import { reactive, toRefs ,onMounted ,onUnmounted} from 'vue';
import { get } from '../library/serviceUtil';
import { useRouter } from 'vue-router';
import toggleMenu from '../components/toggleMenu';
import Menu from '../library/Menu';
import Common from '../library/Common';
import Config from '../library/Config';
import '../css/font.css';

export default {
	name: 'blessing',
	components: {
		toggleMenu,
	},
    setup(){
		const router = useRouter();
		const common = new Common();
		const menu = new Menu("blessing",router,common);
		let activity = null;

		const BlessingColor = Config.BlessingColor.map((item)=>{
			item['border-radius'] = '200px';
			item['font-weight'] = '600';
			return item;
		});

		let state = reactive({
			menuItems:null,
			barrageList: [],
			readyList:[],
			onList:[],
			bg:null,
			bg_music:null,
			sendInterval:null,
			runInterval:null,
		});
		
		const clickMenu = function(item){
			menu.click(item);
		}
		const add = function(list){
			state.sendInterval = setInterval(()=>{
				if(state.readyList.length>0){
					var item = state.readyList.pop();
					state.barrageList.push(item);
				}
			},1000);
		}

		const getProgram = async()=>{
			var res = await get(`/activity/program/${activity.blessing.id}/`);
			if(res.status == 200){
				state.bg = res.data.banner;
				state.bg_music = res.data.bg_music;
				menu.setMusic(res.data.bg_music);
			}

			
		}

		const getMessage = async()=>{
			var res = await get(`/activity/program/blessing/?program_id=${activity.blessing.id}`);
			if(res.status == 200){
				var list = res.data.map((item)=>{
					let color = JSON.parse(JSON.stringify(BlessingColor[parseInt(Math.random()*BlessingColor.length)]));
					let time = parseInt(Math.random()*5)+5;
					return {
						id: item.id,
						avatar:item.avatar_url,
						msg:item.content,
						time:time,
						style:color
					}
				});
				var fliterList = list.filter((item)=>{
					var find = state.onList.find((barrage)=>{
						return barrage.id == item.id;
					})
					return find == null;
				});
				for(var item of fliterList){
					state.readyList.push(item);
					state.onList.push(item);
				}
			}
		}
		onMounted(async()=>{
			await menu.init();
			state.menuItems = menu.menuItems;
			activity = common.getLocal("activity");
			await getProgram();
			state.runInterval = setInterval(()=>{
				getMessage();
			},5000);
			getMessage();
			add();
		});


		onUnmounted(()=>{
			clearInterval(state.sendInterval);
			clearInterval(state.runInterval);
		})
		return {
			...toRefs(state),
			clickMenu,
		};
	}
}
</script>
<style scoped>
#page{
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-image: url('../assets/bg.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
	cursor:default;
}
.content{
	width: 100%;
	height: 100%;
	border:1px solid #000;
}

</style>